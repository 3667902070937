.mui-grid-wrapper {
  background-color: var(--white);
  overflow: auto;
  border-radius: var(--grid-border-radius);
  height: 100%;
  width: 100%;
  min-height: 370px;
  td {
    &.grid-disabled-row {
      color: #aeabab;
      pointer-events: none;
    }
  }
  .enable-grid-cell {
    pointer-events: unset;
  }
  .grid-container {
    border-collapse: separate;
    .grid-header {
      background-color: var(--primary-color);
      color: var(--white);
      svg path {
        fill: var(--white);
      }
      border: none;
      &.audit-header-wrapper {
        .grid-cell,
        &.grid-header {
          background-color: var(--content-bg);
        }
        .grid-header-cell {
          color: var(--primary-color);
          .sort-icon {
            svg {
              path {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
      th {
        border: none;
        font-weight: 900;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--white);
        background-color: var(--grid-primary);
        &:first-child {
          border-radius: var(--grid-border-radius) 0 0 var(--grid-border-radius);
          width: 50px;
          max-width: 50px;
          min-width: 50px;
          display: none;
        }
        &:last-child {
          border-radius: 0 var(--grid-border-radius) var(--grid-border-radius) 0;
        }
      }

      .grid-cell {
        color: var(--white);
        background-color: var(--grid-primary);
      }

      .grid-header-cell {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: var(--grid-header-foreground-color);
        color: var(--white);
        .sort-icon {
          cursor: pointer;
          svg {
            path {
              fill: var(--white);
            }
          }
          .asc {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .grid-body {
    td {
      font-size: var(--grid-font-size);
    }
    .grid-row {
      td {
        border-bottom: var(--grid-borders-row);
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 16px;
        height: 50px;
        border-top: 2px solid transparent;
        border-bottom: 2px solid var(--content-bg);
        &:first-child {
          border-left: 1px solid transparent;
          display: none;
        }
        &:last-child {
          border-right: 1px solid transparent;
        }
      }
      &:hover {
        background-color: #f2f9ff;
        td {
          border-top: 2px solid #d4e9ff;
          border-bottom: 2px solid #d4e9ff;
          &:first-child {
            border-left: 1px solid #d4e9ff;
          }
          &:last-child {
            border-right: 1px solid #d4e9ff;
          }
        }
      }
    }

    .checked {
      background-color: #f2f9ff;
      td {
        border-top: 1px solid #d4e9ff;
        border-bottom: 1px solid #d4e9ff;
      }
      td:first-child {
        border-left: 1px solid #d4e9ff;
      }
      td:last-child {
        border-right: 1px solid #d4e9ff;
      }
    }
    .grid-wrapper {
      .grid-row {
        background-color: var(--grid-row-background-color);
      }
    }
  }
  .grid-cell-checkbox {
    padding: 0 16px;
    .grid-checkbox {
      color: var(--grid-icon-color);
    }
  }
  .grid-no-rows-display {
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }
  .empty-grid {
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: var(--primary-color);
  }
  @media (max-width: 850px) {
    max-height: 340px;
  }
}

.expand-icon-cell {
  .expand-icon {
    cursor: pointer;
    svg {
      margin-left: 20px;
      transform: rotate(270deg);
      height: 1.2rem;
      width: 0.8rem;
      path {
        fill: var(--grid-icon-color);
      }
    }
    &.expanded {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.MUI-grid-pagination {
  --grid-font-size: 14px;
  background-color: var(--white);
  border-top: 0.6px solid var(--grid-primary);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0 8px;
  .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-outlined {
    align-items: center;
    border: 1px solid #11316E;
    display: flex;
    font-size: 12px;
    height: 40px !important;
    outline: none;
    padding: 10px 20px !important;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    margin-top: 0 !important;
    width: 100%;
  }
  .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-root.MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
  .MuiFormControl-root {
    margin: 0;
  }
  .grid-pagination-section {
    padding: 10px 0;
    display: flex;
    align-items: center;
    flex-grow: 1;

    .MuiPaginationItem-page {
      border-radius: 0px;
      border-radius: 5px;
      font-size: 12px;
      &.Mui-selected {
        background-color: var(--grid-primary);
        color: white;
        &:hover {
          background-color: var(--grid-primary);
          color: white;
        }
      }
    }
    .MuiPaginationItem-previousNext {
      display: none;
    }
    .grid-pagination-numbers {
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .MuiPaginationItem-ellipsis {
      font-weight: 900;
    }
  }
  .grid-pagination-section button {
    text-decoration: none;
    border: none;
    background-color: transparent;
    font-size: var(--grid-font-size);
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    &.prev {
      margin-right: 10px;
      svg {
        margin-right: 1.2rem;
      }
      &.disabled {
        opacity: 0.5;
        cursor: default;
        svg path {
          opacity: 0.5;
        }
      }
    }
    &.next {
      margin-left: 10px;
      svg {
        // transform: rotate(-180deg);
        margin-left: 1.2rem;
      }
      &.disabled {
        opacity: 0.5;
        cursor: default;
        svg path {
          opacity: 0.5;
        }
      }
    }
  }
  .grid-row-count {
    font-size: 1.4rem;
    margin-left: -40px;
  }
  .grid-total-row-count {
    margin-right: 1.4rem;
    // font-weight: 600;
  }
  .grid-pagination-page-sizes {
    flex-grow: 1;
  }
  .grid-pagination-page-sizes {
    .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-outlined {
      border: 0;
      width: auto;
      color: var(--primary-color);
      &:hover {
        border: 0;
      }
    }
  }
}
.MUI-grid-outer-header {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;
  flex-wrap: wrap;
  .grid-outer-header-left-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    .grid-header-title {
      font-size: 18px;
      color: #505353;
    }
  }
  .grid-header-search {
    cursor: pointer;
    position: relative;
    .input-group {
      background-color: var(--white);
      .input-control {
        border-color: #e0e1e0;
      }
      input {
        &::placeholder {
          color: #e0e1e0;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }
      }
      .affix.prefix {
        padding: 10px;
        margin: 10px 0px 10px 10px;
        background-color: var(--content-bg);
        border-radius: 4px;
        .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-outlined {
          color: var(--primary-color);
          padding: 5px !important;
          height: 20px !important;
          display: flex;
          align-items: center;
          outline: none;
          font-size: 12px;
          border: none;
        }
      }
    }
    .grid-header-search-dropdown {
      min-width: 100%;
      max-height: 50rem;
      right: auto;
      left: 0;
      overflow: auto;
      padding: 1rem;
      position: absolute;
      background: white;
      border-radius: var(--d-border-radius);
      z-index: 1;
      flex-direction: column;
    }
    input[type="search"] {
      color: var(--font-primary);
      line-height: 1.2;
      font-size: var(--grid-font-size);
      outline: none;
      border: none;
      background-color: transparent;
      flex-grow: 1;
    }
    &:focus-within .grid-header-search-dropdown {
      display: flex;
    }
  }
  .grid-outer-header-right-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
  }
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  li {
    font-size: 12px;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  svg {
    top: calc(50% - 5px) !important;
  }
}
.grid-pagination-page-sizes
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  svg {
    top: calc(50% - 17px) !important;
    margin-top: 4px;
    left: 37px;
    path {
      fill: var(--primary-color);
    }
  }
}
