.App {
  .header-container {
    background-color: aqua;
  }
  .main-container {
    margin-top: 64px;
    background: #e4e5e8;
    height: calc(100vh - 64px);
    overflow: auto;
  }
}
.font-weight-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}
