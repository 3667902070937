.societies-wrapper {
  .bar-chart-wrapper {
    overflow: auto;

    .css-1eg20ca-MuiResponsiveChart-container {
      width: 100%;
    }
  }

  .society-label {
    font-weight: bold;
    text-align: center;
  }

  .note-text {
    font-weight: bold;
    text-align: center;
  }

  .award-name-wrapper {
    color: #239;
    cursor: pointer;
  }

  .society-left-wrapper {
    position: sticky;
    top: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    width: 50%;

    @media (max-width: 1224px) {
      position: static;
      width: 100%;
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}