.slider-view {
  position: fixed;
  right: -2200px;
  z-index: 11;
  overflow: auto;
  top: 0px;
  width: 550px;
  max-width: calc(100% - 20px);
  background: var(--white-color);
  background: #ffffff;
  height: 100%;
  transition: all 0.8s ease-in-out;
  z-index: 1002;
  border-radius: 12px 12px 0px 12px;
}

.slider-content {
  position: relative;
  z-index: 1002;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: var(--white-color);
  background-color: #fff;
  border-radius: 5px;
  border: 0 !important;
  box-shadow: none !important;
}

.slider-body {
  --header-height: 50px;
  margin: 0;
  background: var(--white-color);
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  height: calc(100% - var(--header-height));
  padding: 14px 60px;
  overflow: auto;
  cursor: default;
}

.slider-header {
  --header-height: 50px;
  padding: 0px 10px !important;
  background-color: var(--neutral-bg);
  background-color: #11316e;
  border-radius: 12px 0px 0px 0px;
  border: none;
  font-family: "Aileron", sans-serif !important;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  border: 0 !important;
  height: var(--header-height);

  .slider-title {
    margin: 0;
  }
}

.slider-overlay {
  display: none;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--overlay-bg);
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 10px 20px var(--overlay-shadow);
  box-shadow: 0px 10px 20px rgba(6, 38, 79, 0.06);

  &.add-overlay {
    display: block;
  }
}

.slider-view.open-slider-view {
  right: 0;
}

.slider-open {
  overflow-y: hidden !important;
}

.slider-close {
  font-size: 22px;
  color: var(--white-color);
  color: #ffffff;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.MuiAppBar-root.MuiPaper-root {
  z-index: 1000;
}
