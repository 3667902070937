:root {
  --primary-color: #003767;
  --font-primary: #505353;
  --white: #ffffff;
  --content-bg: #f4f8f9;
  --grid-primary: #11316e;
  --input-color: #505353;
  --error: #d70825;
  --toggle-switch-bg: #71af2d;
  --overlay-bg: rgba(0, 0, 0, 0.5);
  --overlay-shadow: rgba(6, 38, 79, 0.06);
  --error-display-background: #fbb800;
  --button-primary: #11316e;
  //sizes
  --grid-font-size: 12px;
  --grid-border-radius: 10px 10px 0px 0px;
  --z-header: 1000;
  --header-padding: 10px;
  --d-header-height: 10px;
}
