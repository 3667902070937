.uta-faculty-awards-container {
  .awards-and-honors {
    color: #000;
  }

  .awards-and-honors-container-wrapper {
    .left-wrapper {
      min-width: 30%;
      max-width: 30%;
      position: sticky;
      top: 0;
      max-height: 100vh;
      overflow-y: auto;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 1224px) {
        position: static;
        min-width: 100%;
        max-width: 100%;
        margin-right: 0 !important;
      }

      .uta-faculty-award-wrapper {
        border-bottom: solid 1px #ccc;
        padding: 4px 0px;

        &.active {
          color: var(--primary-color);
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .uta-faculty-side-header-wrapper {
        text-align: center;
        padding: 10px;
        background-color: var(--primary-color);
        text-transform: uppercase;
        color: #fff;
      }
    }

    .right-wrapper {
      .uta-faculty-award-header-wrapper {
        color: #000;
        font-size: 28px;
        font-weight: bold;
      }

      .faculty-data-wrapper {
        border-top: 1px solid #ccc;

        .profile-img {
          height: 164px;
          width: 100%;
          min-width: 300px;
          object-fit: contain;
          border: 1px solid #ddd;
          padding: 5px;
        }

        .faculty-data-container {
          @media (max-width: 1224px) {
            flex-direction: column;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 1224px) {
      flex-direction: column;
      width: 100%;
    }
  }
}