// .counts-wrapper {
//   padding: 16px;
// }

.counts-container {
  display: flex;
  align-items: center;
  position: relative;
}

.card-slider {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.card-slider::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.card-container {
  min-width: 250px; /* Ensure all cards have the same width */
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for card */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transform and shadow transition */
}

.card-container:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}

.card-container img {
  width: 100%;
  height: 150px; /* Set a fixed height for images */
  object-fit: cover;
  border-bottom: 1px solid #ddd; /* Divider between image and content */
}

.card-content {
  padding: 16px;
  text-align: center;
}

.card-content h6 {
  margin: 0;
  font-size: 1.2rem; /* Slightly larger font size */
  color: #333; /* Darker text color */
}

.card-content h4 {
  margin: 8px 0 0;
  font-size: 2rem; /* Larger font size for count */
  color: #555; /* Subtle text color */
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f0f0f0;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  padding: 14px;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

/* Shadow Effect */
.nav-button.shadow-hover:hover {
  background-color: #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.nav-button:active {
  background-color: #ccc;
  transform: translateY(-50%) scale(0.9); /* Slight shrink on click */
}

.nav-button.prev {
  left: 10px;
}

.nav-button.next {
  right: 10px;
}

.nav-button.hidden {
  display: none;
}

.rendering-svg {
  max-height: 65px;
  max-width: 65px;
  svg {
    width: 100%;
    height: 100%;
  }
}

.counts-content {
  min-width: 300px;
}
