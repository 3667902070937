.awardee-fullname-wrapper {
  color: blue;

  .awardee-fullname {
    background-color: #ffff00;
  }
}

.nam-wrapper {
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
}

.awardee-card-wrapper {
  img {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
}

.awards-container-wrapper {
  .award-left-wrapper {
    min-width: 40%;
    max-width: 40%;
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1224px) {
      position: static;
      min-width: 100%;
      max-width: 100%;
      margin-right: 0 !important;
    }

    .uta-faculty-note {
      color: blue;
      background-color: #ffff00;
      padding: 12px;
      border-radius: 10px;
    }

    .award-name {
      text-decoration: none;
    }
  }

  .award-right-wrapper {
    flex-grow: 1;

    .analysis-wrapper {
      color: #ff6633;
    }

    .total-awardees-count-wrapper {
      text-align: left;
      color: #ff6633;
    }


  }



  @media (max-width: 1224px) {
    flex-direction: column;
  }
}

.bar-chart-container-wrapper {
  div {
    width: 100% !important;
  }
}