.container {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin: 5px 0;
}

#networkGraph {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: 5px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
  left: -10px;
}

.label-container {
  display: flex;
  flex-wrap: wrap;
}

.label-item {
  margin: 5px 10px;
  display: flex;
  align-items: center;
}

.label-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

circle:hover {
  cursor: pointer;
}

.container-wrapper {
  display: flex;
}

.legend-netwrok-graph-wrapper {
  .legend-wrapper {
    .color-legend {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      border-radius: 50%;
      min-width: 30px;
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}

.network-graph {
  height: 500px;
  min-height: 500px;
  overflow: hidden;
  width: 100%;
}

.note-wrapper {
  color: #f63;
}

.awards-dropdown-wrapper {
  flex-direction: column !important;
  gap: 1rem !important;
}