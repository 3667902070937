html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  color: var(--font-primary);
  background-color: var(--main-bg);
  font-family: "Aileron", sans-serif !important;
  overflow: auto;
  transition: all 0.2s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
