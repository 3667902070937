.awardee-full-name-wrapper {
  border-bottom: solid 1px #ccc;
  padding: 4px 0px;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.awardees-header-title-wrapper {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
