.award-wrapper {
  border: 1px solid;
  border-radius: 10px;

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
}

.award-title-wrapper {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}